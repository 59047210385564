import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const ListsWidget = ({
  title,
  content,
  titleHref = "",
  isClickable = false,
  isIconClickable = false,
  color = "primary",
  icon,
  href,
  showIcon = true,
  margin = "mb-5",
  showEdit = false,
  handleEdit = () => {},
  handleClick = () => {},
  showQr = false,
  handleShowQr = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div className={`d-flex align-items-center ${margin}`}>
      {showIcon && (
        <div className={`symbol symbol-40 symbol-light-${color} mr-5`}>
          {isIconClickable ? (
            <button
              onClick={handleClick}
              className="symbol-label"
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "#fff",
                padding: "0",
              }}
            >
              <span className={`svg-icon svg-icon-lg svg-icon-${color}`}>
                {icon}
              </span>
            </button>
          ) : (
            <span
              className="symbol-label"
              style={{ backgroundColor: "#F0F4F6" }}
            >
              <span className={`svg-icon svg-icon-lg svg-icon-${color}`}>
                <span className={`svg-icon svg-icon-lg svg-icon-${color}`}>
                  {icon}
                </span>
              </span>
            </span>
          )}
        </div>
      )}
      <div className="d-flex flex-column font-weight-bold">
        {titleHref.length > 0 ? (
          <Link className="text-muted" to={titleHref}>
            {title}
          </Link>
        ) : (
          <div style={{ display: "inline", alignItems: "baseline" }}>
            <span className="text-muted" style={{ textAlign: "left" }}>
              {title}
            </span>
            {showEdit && (
              <Button
                style={{ marginLeft: "auto" }}
                color="primary"
                onClick={handleEdit}
              >
                {t("edit")}
              </Button>
            )}
            {showQr && (
              <Button
                style={{ marginLeft: "auto" }}
                color="primary"
                onClick={handleShowQr}
              >
                {t("qr-code")}
              </Button>
            )}
          </div>
        )}
        {isClickable ? (
          <a
            onClick={handleClick}
            className="text-dark text-hover-primary mb-1 font-size-lg"
            style={{ wordBreak: "break-all" }}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content}
          </a>
        ) : (
          <span
            onClick={handleClick}
            className={`text-dark text-hover-${color} mb-1 font-size-lg`}
            style={{ wordBreak: "break-all" }}
          >
            {content}
          </span>
        )}
      </div>
    </div>
  );
};

export default ListsWidget;
