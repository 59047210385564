import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ItemsTable = ({ items, changePage, page, paginationCount }) => {
  const { t } = useTranslation();

  return (
    <>
      {items.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "30px" }}>
                    <span className="text-dark-75">{t("type")}</span>
                  </th>
                  <th style={{ minWidth: "30px" }}>{t("product-name")}</th>
                  <th style={{ minWidth: "30px" }}>{t("txid")}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4"></div>
                          <div>
                            <span className="text-dark-75 font-weight-bold text-hover-primary mb-1 font-size-lg">
                              {item.type}
                            </span>
                            <span className="text-muted font-weight-bold d-block"></span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bold d-block font-size-lg">
                          {item.value.classification.displayName}
                        </span>
                        <span className="text-muted font-weight-bold"></span>
                      </td>

                      <td className="pr-0">
                        <Link
                          to={
                            item.type === "item"
                              ? `/inventory/item/${item.value._id}`
                              : item.type === "group"
                              ? `/item-groups/group/${item.value._id}`
                              : "/"
                          }
                        >
                          <Button
                            style={{
                              width: "10px",
                              marginRight: "-1rem",
                              padding: 0,
                            }}
                          >
                            {item.value.txid.slice(0, 5)}
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
            <Pagination
              size="small"
              count={paginationCount}
              color="primary"
              page={page}
              onChange={(event, page) => changePage(page)}
              style={{ float: "right", marginTop: "20px" }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ItemsTable;
