export const capitalizeFirst = (text) => {
  return text[0].toUpperCase() + text.substr(1);
};

export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1000 ** i).toFixed(1)} ${sizes[i]}`;
};
